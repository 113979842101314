<template>
    <div class="tolerance-widget">
        <div class="text-center table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 265px;">Account</th>
                        <th style="width: 92.2px;">Today</th>
                        <th style="width: 137.2px;">Yesterday</th>
                        <th style="width: 77.2px;">WTD</th>
                        <th style="width: 72.2px;">MTD</th>
                        <th style="width: 69.2px;">YTD</th>
                        <th style="width: 149.2px;">Drawdown</th>
                        <th style="width: 201.2px;">Open Positions</th>
                        <th style="width: 216.2px;">Closed Positions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{accounts['New Track Record'].name}}</td>
                        <td>{{accounts['New Track Record'].total | number | percentage}}</td>
                        <td>{{accounts['New Track Record'].yesterday | number(2, true) | percentage}}</td>
                        <td>{{accounts['New Track Record'].wtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['New Track Record'].mtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['New Track Record'].ytd | number(2, true) | percentage}}</td>
                        <td>{{accounts['New Track Record'].drawdown | number(2, true) | percentage}}</td>
                        <td>{{accounts['New Track Record'].openPositions}}</td>
                        <td>{{accounts['New Track Record'].closedPositions}}</td>
                    </tr>
                    <tr>
                        <td>{{accounts['gain.fx.gbp.1'].name}}</td>
                        <td>{{accounts['gain.fx.gbp.1'].total | number | percentage}}</td>
                        <td>{{accounts['gain.fx.gbp.1'].yesterday | number(2, true) | percentage}}</td>
                        <td>{{accounts['gain.fx.gbp.1'].wtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['gain.fx.gbp.1'].mtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['gain.fx.gbp.1'].ytd | number(2, true) | percentage}}</td>
                        <td>{{accounts['gain.fx.gbp.1'].drawdown | number(2, true) | percentage}}</td>
                        <td>{{accounts['gain.fx.gbp.1'].openPositions}}</td>
                        <td>{{accounts['gain.fx.gbp.1'].closedPositions}}</td>
                    </tr>
                    <tr>
                        <td>{{accounts['jefferies.fx.tolerance'].name}}</td>
                        <td>{{accounts['jefferies.fx.tolerance'].total | number | percentage}}</td>
                        <td>{{accounts['jefferies.fx.tolerance'].yesterday | number(2, true) | percentage}}</td>
                        <td>{{accounts['jefferies.fx.tolerance'].wtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['jefferies.fx.tolerance'].mtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['jefferies.fx.tolerance'].ytd | number(2, true) | percentage}}</td>
                        <td>{{accounts['jefferies.fx.tolerance'].drawdown | number(2, true) | percentage}}</td>
                        <td>{{accounts['jefferies.fx.tolerance'].openPositions}}</td>
                        <td>{{accounts['jefferies.fx.tolerance'].closedPositions}}</td>
                    </tr>
                    <tr>
                        <td>Variance</td>
                        <td :class="getVarianceClass('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'total')">
                            {{difference('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'total') | number | percentage}}
                        </td>
                        <td :class="getVarianceClass('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'yesterday', true)">
                            {{difference('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'yesterday') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'wtd', true)">
                            {{difference('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'wtd') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'mtd', true)">
                            {{difference('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'mtd') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'ytd', true)">
                            {{difference('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'ytd') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'drawdown', true)">
                            {{difference('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'drawdown') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'openPositions')">
                            {{Math.abs(difference('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'openPositions'))}}
                        </td>
                        <td :class="getVarianceClass('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'closedPositions')">
                            {{Math.abs(difference('jefferies.fx.tolerance', 'gain.fx.gbp.1', 'closedPositions'))}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 265px;">Account</th>
                        <th style="width: 92.2px;">Today</th>
                        <th style="width: 137.2px;">Yesterday</th>
                        <th style="width: 77.2px;">WTD</th>
                        <th style="width: 72.2px;">MTD</th>
                        <th style="width: 69.2px;">YTD</th>
                        <th style="width: 149.2px;">Drawdown</th>
                        <th style="width: 201.2px;">Open Positions</th>
                        <th style="width: 216.2px;">Closed Positions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{accounts['Futures Track Record'].name}}</td>
                        <td>{{accounts['Futures Track Record'].total | number | percentage}}</td>
                        <td>{{accounts['Futures Track Record'].yesterday | number(2, true) | percentage}}</td>
                        <td>{{accounts['Futures Track Record'].wtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['Futures Track Record'].mtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['Futures Track Record'].ytd | number(2, true) | percentage}}</td>
                        <td>{{accounts['Futures Track Record'].drawdown | number(2, true) | percentage}}</td>
                        <td>{{accounts['Futures Track Record'].openPositions}}</td>
                        <td>{{accounts['Futures Track Record'].closedPositions}}</td>
                    </tr>
                    <tr>
                        <td>{{accounts['adm.9179426a'].name}}</td>
                        <td>{{accounts['adm.9179426a'].total | number | percentage}}</td>
                        <td>{{accounts['adm.9179426a'].yesterday | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.9179426a'].wtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.9179426a'].mtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.9179426a'].ytd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.9179426a'].drawdown | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.9179426a'].openPositions}}</td>
                        <td>{{accounts['adm.9179426a'].closedPositions}}</td>
                    </tr>
                    <tr>
                        <td>{{accounts['adm.91794353'].name}}</td>
                        <td>{{accounts['adm.91794353'].total | number | percentage}}</td>
                        <td>{{accounts['adm.91794353'].yesterday | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794353'].wtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794353'].mtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794353'].ytd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794353'].drawdown | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794353'].openPositions}}</td>
                        <td>{{accounts['adm.91794353'].closedPositions}}</td>
                    </tr>
                    <tr>
                        <td>{{accounts['adm.91794266'].name}}</td>
                        <td>{{accounts['adm.91794266'].total | number | percentage}}</td>
                        <td>{{accounts['adm.91794266'].yesterday | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794266'].wtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794266'].mtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794266'].ytd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794266'].drawdown | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794266'].openPositions}}</td>
                        <td>{{accounts['adm.91794266'].closedPositions}}</td>
                    </tr>
                    <tr>
                        <td>{{accounts['cme.futures.demo.tolerance'].name}}</td>
                        <td>{{accounts['cme.futures.demo.tolerance'].total | number | percentage}}</td>
                        <td>{{accounts['cme.futures.demo.tolerance'].yesterday | number(2, true) | percentage}}</td>
                        <td>{{accounts['cme.futures.demo.tolerance'].wtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['cme.futures.demo.tolerance'].mtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['cme.futures.demo.tolerance'].ytd | number(2, true) | percentage}}</td>
                        <td>{{accounts['cme.futures.demo.tolerance'].drawdown | number(2, true) | percentage}}</td>
                        <td>{{accounts['cme.futures.demo.tolerance'].openPositions}}</td>
                        <td>{{accounts['cme.futures.demo.tolerance'].closedPositions}}</td>
                    </tr>
                    <tr>
                        <td>Variance</td>
                        <td :class="getVarianceClass('cme.futures.demo.tolerance', 'adm.9179426a', 'total')">
                            {{difference('cme.futures.demo.tolerance', 'adm.9179426a', 'total') | number | percentage}}
                        </td>
                        <td :class="getVarianceClass('cme.futures.demo.tolerance', 'adm.9179426a', 'yesterday', true)">
                            {{difference('cme.futures.demo.tolerance', 'adm.9179426a', 'yesterday') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('cme.futures.demo.tolerance', 'adm.9179426a', 'wtd', true)">
                            {{difference('cme.futures.demo.tolerance', 'adm.9179426a', 'wtd') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('cme.futures.demo.tolerance', 'adm.9179426a', 'mtd', true)">
                            {{difference('cme.futures.demo.tolerance', 'adm.9179426a', 'mtd') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('cme.futures.demo.tolerance', 'adm.9179426a', 'ytd', true)">
                            {{difference('cme.futures.demo.tolerance', 'adm.9179426a', 'ytd') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('cme.futures.demo.tolerance', 'adm.9179426a', 'drawdown', true)">
                            {{difference('cme.futures.demo.tolerance', 'adm.9179426a', 'drawdown') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('cme.futures.demo.tolerance', 'adm.9179426a', 'openPositions')">
                            {{Math.abs(difference('cme.futures.demo.tolerance', 'adm.9179426a', 'openPositions'))}}
                        </td>
                        <td :class="getVarianceClass('cme.futures.demo.tolerance', 'adm.9179426a', 'closedPositions')">
                            {{Math.abs(difference('cme.futures.demo.tolerance', 'adm.9179426a', 'closedPositions'))}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 265px;">Account</th>
                        <th style="width: 92.2px;">Today</th>
                        <th style="width: 137.2px;">Yesterday</th>
                        <th style="width: 77.2px;">WTD</th>
                        <th style="width: 72.2px;">MTD</th>
                        <th style="width: 69.2px;">YTD</th>
                        <th style="width: 149.2px;">Drawdown</th>
                        <th style="width: 201.2px;">Open Positions</th>
                        <th style="width: 216.2px;">Closed Positions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{accounts['adm.91794375'].name}}</td>
                        <td>{{accounts['adm.91794375'].total | number | percentage}}</td>
                        <td>{{accounts['adm.91794375'].yesterday | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794375'].wtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794375'].mtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794375'].ytd | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794375'].drawdown | number(2, true) | percentage}}</td>
                        <td>{{accounts['adm.91794375'].openPositions}}</td>
                        <td>{{accounts['adm.91794375'].closedPositions}}</td>
                    </tr>
                    <tr>
                        <td>{{accounts['hft.tolerance'].name}}</td>
                        <td>{{accounts['hft.tolerance'].total | number | percentage}}</td>
                        <td>{{accounts['hft.tolerance'].yesterday | number(2, true) | percentage}}</td>
                        <td>{{accounts['hft.tolerance'].wtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['hft.tolerance'].mtd | number(2, true) | percentage}}</td>
                        <td>{{accounts['hft.tolerance'].ytd | number(2, true) | percentage}}</td>
                        <td>{{accounts['hft.tolerance'].drawdown | number(2, true) | percentage}}</td>
                        <td>{{accounts['hft.tolerance'].openPositions}}</td>
                        <td>{{accounts['hft.tolerance'].closedPositions}}</td>
                    </tr>
                    <tr>
                        <td>Variance</td>
                        <td :class="getVarianceClass('hft.tolerance', 'adm.91794375', 'total')">
                            {{difference('hft.tolerance', 'adm.91794375', 'total') | number | percentage}}
                        </td>
                        <td :class="getVarianceClass('adm.91794375', 'adm.91794375', 'yesterday', true)">
                            {{difference('adm.91794375', 'adm.91794375', 'yesterday') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('hft.tolerance', 'adm.91794375', 'wtd', true)">
                            {{difference('hft.tolerance', 'adm.91794375', 'wtd') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('hft.tolerance', 'adm.91794375', 'mtd', true)">
                            {{difference('hft.tolerance', 'adm.91794375', 'mtd') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('hft.tolerance', 'adm.91794375', 'ytd', true)">
                            {{difference('hft.tolerance', 'adm.91794375', 'ytd') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('hft.tolerance', 'adm.91794375', 'drawdown', true)">
                            {{difference('hft.tolerance', 'adm.91794375', 'drawdown') | number(2, true) | percentage}}
                        </td>
                        <td :class="getVarianceClass('hft.tolerance', 'adm.91794375', 'openPositions')">
                            {{Math.abs(difference('hft.tolerance', 'adm.91794375', 'openPositions'))}}
                        </td>
                        <td :class="getVarianceClass('hft.tolerance', 'adm.91794375', 'closedPositions')">
                            {{Math.abs(difference('hft.tolerance', 'adm.91794375', 'closedPositions'))}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tolerance',
    data() {
        return {
            accounts: {
                'New Track Record': {
                    name: 'FX Track Record',
                    total: 0,
                    yesterday: 0,
                    wtd: 0,
                    mtd: 0,
                    ytd: 0,
                    drawdown: 0,
                    openPositions: 0,
                    closedPositions: 0
                },
                'gain.fx.gbp.1': {
                    name: 'Gain FX GBP 1',
                    total: 0,
                    yesterday: 0,
                    wtd: 0,
                    mtd: 0,
                    ytd: 0,
                    drawdown: 0,
                    openPositions: 0,
                    closedPositions: 0
                },
                'jefferies.fx.tolerance': {
                    name: 'Gain Tolerance',
                    total: 0,
                    yesterday: 0,
                    wtd: 0,
                    mtd: 0,
                    ytd: 0,
                    drawdown: 0,
                    openPositions: 0,
                    closedPositions: 0
                },
                'Futures Track Record': {
                    name: 'Futures Track Record',
                    total: 0,
                    yesterday: 0,
                    wtd: 0,
                    mtd: 0,
                    ytd: 0,
                    drawdown: 0,
                    openPositions: 0,
                    closedPositions: 0
                },
                'adm.9179426a': {
                    name: 'ADM Futures Holding Account',
                    total: 0,
                    yesterday: 0,
                    wtd: 0,
                    mtd: 0,
                    ytd: 0,
                    drawdown: 0,
                    openPositions: 0,
                    closedPositions: 0
                },
                'adm.91794353': {
                    name: 'ADM Futures 1',
                    total: 0,
                    yesterday: 0,
                    wtd: 0,
                    mtd: 0,
                    ytd: 0,
                    drawdown: 0,
                    openPositions: 0,
                    closedPositions: 0
                },
                'adm.91794266': {
                    name: 'ADM Futures 2',
                    total: 0,
                    yesterday: 0,
                    wtd: 0,
                    mtd: 0,
                    ytd: 0,
                    drawdown: 0,
                    openPositions: 0,
                    closedPositions: 0
                },
                'cme.futures.demo.tolerance': {
                    name: 'CME Futures Tolerance',
                    total: 0,
                    yesterday: 0,
                    wtd: 0,
                    mtd: 0,
                    ytd: 0,
                    drawdown: 0,
                    openPositions: 0,
                    closedPositions: 0
                },
                'adm.91794375': {
                    name: 'ADM HFT',
                    total: 0,
                    yesterday: 0,
                    wtd: 0,
                    mtd: 0,
                    ytd: 0,
                    drawdown: 0,
                    openPositions: 0,
                    closedPositions: 0
                },
                'hft.tolerance': {
                    name: 'HFT Tolerance',
                    total: 0,
                    yesterday: 0,
                    wtd: 0,
                    mtd: 0,
                    ytd: 0,
                    drawdown: 0,
                    openPositions: 0,
                    closedPositions: 0
                }
            },
            thresholds: {
                total: 0.02,
                yesterday: 0.02,
                wtd: 0.02,
                mtd: 0.02,
                ytd: 0.02,
                drawdown: 0.02,
                openPositions: 1,
                closedPositions: 1
            },
            hasSetupListeners: false
        };
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('todayreturn', this.onTodayReturn);
        this.$ws.off('growthsummary', this.onGrowthSummary);
        this.$ws.off('drawdown', this.onDrawdown);
        this.$ws.off('opentrades', this.onOpenTrades);
        this.$ws.off('closedtrades', this.onClosedTrades);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('todayreturn', this.onTodayReturn);
                this.$ws.on('growthsummary', this.onGrowthSummary);
                this.$ws.on('drawdown', this.onDrawdown);
                this.$ws.on('opentrades', this.onOpenTrades);
                this.$ws.on('closedtrades', this.onClosedTrades);
                this.hasSetupListeners = true;
            }

            for(let account in this.accounts) {
                this.$ws.send({
                    sessionID: 'NotImplemented',
                    accountName: account,
                    request: 'TodayReturn'
                });

                this.$ws.send({
                    sessionID: 'NotImplemented',
                    accountName: account,
                    request: 'GrowthSummary'
                });

                this.$ws.send({
                    sessionID: 'NotImplemented',
                    accountName: account,
                    request: 'Drawdown'
                });

                this.$ws.send({
                    sessionID: 'NotImplemented',
                    accountName: account,
                    request: 'OpenTrades'
                });

                this.$ws.send({
                    sessionID: 'NotImplemented',
                    accountName: account,
                    request: 'ClosedTrades'
                });
            }
        },
        onTodayReturn(event) {
            let response = event.response.return;

            this.accounts[event.request.accountName].total = response.Total;
        },
        onGrowthSummary(event) {
            let response = event.response;

            this.accounts[event.request.accountName].yesterday = response.yesterdayPercent;
            this.accounts[event.request.accountName].wtd = response.weekPercent;
            this.accounts[event.request.accountName].mtd = response.monthPercent;
            this.accounts[event.request.accountName].ytd = response.yearPercent;
        },
        onDrawdown(event) {
            this.accounts[event.request.accountName].drawdown = event.response;
        },
        onOpenTrades(event) {
            this.accounts[event.request.accountName].openPositions = event.response.length;
        },
        onClosedTrades(event) {
            this.accounts[event.request.accountName].closedPositions = event.response.length;
        },
        difference(account1, account2, key) {
            return (parseFloat(this.accounts[account1][key]) - parseFloat(this.accounts[account2][key])) * -1;
        },
        hitsThreshold(account1, account2, key) {
            return parseFloat(this.accounts[account1][key]) - parseFloat(this.accounts[account2][key]) >= this.thresholds[key];
        },
        getVarianceClass(account1, account2, key, multiply = false) {
            let difference = this.difference(account1, account2, key);
            let threshold = this.thresholds[key];

            if(multiply) {
                difference *= 100;
            }

            if(key === 'openPositions' || key === 'closedPositions') {
                if(Math.abs(difference) >= threshold) {
                    return 'negative-variance';
                }

                return;
            }

            if(difference >= threshold) {
                return 'positive-variance';
            } else if(difference <= (threshold * -1)) {
                return 'negative-variance';
            }

            return '';
        }
    }
}
</script>

<style lang="scss" scoped>
.table {
    td {
        &.negative-variance {
            color: red;
        }

        &.positive-variance {
            color: green;
        }
    }
}
</style>