<template>
    <div class="position-summary-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-select v-model="dataType" :options="dataTypeOptions"></b-select>
            </div>
        </div>
        <b-table v-if="dataType === 'leverage'" :items="filterLeverageItems()" :fields="columns" responsive class="text-center" show-empty>
            <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <input type="text" v-model="filters[field.key]" placeholder="Search..." class="form-control" />
                </td>
            </template>
            <template slot="empty">
                No results found
            </template>
            <template v-slot:[getCell(columns[2].key)]="data">
                <template v-if="data.value === 0">-</template>
                <template v-else>
                    {{data.value | number(2, true)}}
                </template>
            </template>
            <template v-slot:[getCell(columns[3].key)]="data">
                <template v-if="data.value === 0">-</template>
                <template v-else>
                    {{data.value | number(2, true)}}
                </template>
            </template>
            <template v-slot:[getCell(columns[4].key)]="data">
                <template v-if="data.value === 0">-</template>
                <template v-else>
                    {{data.value | number(2, true)}}
                </template>
            </template>
            <template v-slot:[getCell(columns[5].key)]="data">
                <template v-if="data.value === 0">-</template>
                <template v-else>
                    {{data.value | number(2, true)}}
                </template>
            </template>
            <template v-slot:[getCell(columns[6].key)]="data">
                <template v-if="data.value === 0">-</template>
                <template v-else>
                    {{data.value | number(2, true)}}
                </template>
            </template>
            <template v-slot:[getCell(columns[7].key)]="data">
                <template v-if="data.value === 0">-</template>
                <template v-else>
                    {{data.value | number(2, true)}}
                </template>
            </template>
            <template v-slot:[getCell(columns[8].key)]="data">
                <template v-if="data.value === 0">-</template>
                <template v-else>
                    {{data.value | number(2, true)}}
                </template>
            </template>
            <template v-slot:[getCell(columns[9].key)]="data">
                <template v-if="data.value === 0">-</template>
                <template v-else>
                    {{data.value | number(2, true)}}
                </template>
            </template>
        </b-table>
        <b-table v-else :items="filterPositionItems()" :fields="columns" responsive class="text-center" show-empty>
            <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <input type="text" v-model="filters[field.key]" placeholder="Search..." class="form-control" />
                </td>
            </template>
            <template slot="empty">
                No results found
            </template>
            <template v-slot:[getCell(columns[2].key)]="data">
                {{data.value | number(0)}}
            </template>
            <template v-slot:[getCell(columns[3].key)]="data">
                {{data.value | number(0)}}
            </template>
            <template v-slot:[getCell(columns[4].key)]="data">
                {{data.value | number(0)}}
            </template>
            <template v-slot:[getCell(columns[5].key)]="data">
                {{data.value | number(0)}}
            </template>
            <template v-slot:[getCell(columns[6].key)]="data">
                {{data.value | number(0)}}
            </template>
            <template v-slot:[getCell(columns[7].key)]="data">
                {{data.value | number(0)}}
            </template>
            <template v-slot:[getCell(columns[8].key)]="data">
                {{data.value | number(0)}}
            </template>
            <template v-slot:[getCell(columns[9].key)]="data">
                {{data.value | number(0)}}
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    name: 'position-summary',
    data() {
        let columns = [
            {
                key: 'symbol',
                label: 'Symbol',
                sortable: true
            },
            {
                key: 'strategy',
                label: 'Strategy',
                sortable: true
            },
            {
                key: 'Gain FX GBP 1',
                label: 'Gain FX GBP 1',
                sortable: true
            },
            {
                key: 'Gain Tolerance',
                label: 'Gain Tolerance',
                sortable: true
            },
            {
                key: 'ADM Futures Holding Account',
                label: 'ADM Futures Holding Account',
                sortable: true
            },
            {
                key: 'ADM Futures 1',
                label: 'ADM Futures 1',
                sortable: true
            },
            {
                key: 'ADM Futures 2',
                label: 'ADM Futures 2',
                sortable: true
            },
            {
                key: 'CME Futures Tolerance',
                label: 'CME Futures Tolerance',
                sortable: true
            },
            {
                key: 'ADM HFT',
                label: 'ADM HFT',
                sortable: true
            },
            {
                key: 'HFT Tolerance',
                label: 'HFT Tolerance',
                sortable: true
            }
        ];

        let filters = {};
        for(let column of columns) {
            filters[column.key] = '';
        }

        return {
            columns: columns,
            positionItems: [],
            leverageItems: [],
            filters: filters,
            dataType: 'leverage',
            dataTypeOptions: [
                { value: 'leverage', text: 'Leverage' },
                { value: 'position', text: 'Position' }
            ],
            hasSetupListeners: false
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('positionsummary', this.onPositionSummary);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('positionsummary', this.onPositionSummary);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            // this.$ws.send({
            //     sessionID: 'NotImplemented',
            //     accountName: account,
            //     request: 'PositionSummary',
            //     args: {}
            // });
        },
        onPositionSummary(event) {
            for(let item of event.response) {
                let row = {
                    symbol: item.symbol,
                    strategy: item.strategy.Name
                };

                let positionRow = Object.assign({}, row);
                for(let account in item.accountPositions) {
                    positionRow[account] = item.accountPositions[account];
                }

                this.positionItems.push(positionRow);

                let leverageRow = Object.assign({}, row);
                for(let account in item.leverages) {
                    leverageRow[account] = item.leverages[account];
                }

                this.leverageItems.push(leverageRow);
            }
        },
        filterLeverageItems() {
            let filtered = this.leverageItems.filter(item => {
                return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()));
            });

            if(filtered.length > 0) {
                return filtered;
            } else {
                let emptyFilters = {};
                for(let key in this.filters) {
                    emptyFilters[key] = '';
                }

                return [];
            }
        },
        filterPositionItems() {
            let filtered = this.positionItems.filter(item => {
                return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()));
            });

            if(filtered.length > 0) {
                return filtered;
            } else {
                let emptyFilters = {};
                for(let key in this.filters) {
                    emptyFilters[key] = '';
                }

                return [];
            }
        },
        getCell(key) {
            return `cell(${key})`;
        }
    }
}
</script>